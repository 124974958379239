import { type CompanyResponse } from '../models/apiCore'
import { useUserStore } from '@/stores/user'
import { useCompanyStore } from '@/stores/companies'

export default defineNuxtRouteMiddleware(async (to) => {
  // skips middleware on server side -- only helps local dev
  if (process.server) return

  const { $auth, $getSystemRole, $userCan, $getFirstCompanyId } = useNuxtApp()

  const userStore = useUserStore()
  const companiesStore = useCompanyStore()

  try {
    const user = await $auth.amplify.currentAuthenticatedUser()

    if (user.confirmed === false) {
      if (!userStore.returnURL) {
        userStore.setReturnURL(to.fullPath)
      }

      if (to.fullPath !== '/auth/confirm') {
        return navigateTo('/auth/confirm')
      }

      return
    } else if (to.fullPath === '/auth/confirm') {
      userStore.setReturnURL('/')
    }

    if (!companiesStore.companiesList) {
      await companiesStore.loadCompanies()
    }

    // User is a client
    if ($getSystemRole() === 'client_user') {
      // If there are no companies after loading, be forced to create a company
      if (
        companiesStore.companiesList &&
        companiesStore.companiesList.length === 0
      ) {
        // If already routing to the correct page, continue
        if (to.fullPath === '/auth/company') {
          return
        } else {
          return navigateTo('/auth/company')
        }
      }

      // User has companies loaded but no current company
      if (companiesStore.companiesList && !companiesStore.currentCompany?.Id) {
        const companiesSorted = companiesStore.companiesList.sort(
          (a: CompanyResponse, b: CompanyResponse) => {
            const ca = a.CompanyName.toLowerCase()
            const cb = b.CompanyName.toLowerCase()

            return ca < cb ? -1 : ca > cb ? 1 : 0
          },
        )

        // Check if user can access company
        if ($userCan('read', 'company/location', companiesSorted[0].Id)) {
          companiesStore.currentCompany = companiesSorted[0]
        } else {
          // If not just use the first company in the JWT
          const company = companiesStore.companiesList.find(
            (item: CompanyResponse) => {
              return item.Id === $getFirstCompanyId()
            },
          )

          if (company) {
            companiesStore.currentCompany = company
          }
        }
      }
    }

    if (userStore.returnURL && userStore.returnURL !== to.fullPath) {
      return navigateTo(userStore.returnURL)
    }
  } catch (e) {
    return navigateTo('/login')
  }
})
